<template>
  <div class="expande-horizontal pa-3 centraliza fonte">
    <v-flex xs12 class="pr-2">
      <v-text-field
        dense
        solo
        background-color="#f2f2f2"
        small
        flat
        prepend-inner-icon="mdi-magnify"
        label="Busque pela categoria..."
        v-model="filter.search"
        hide-details
        :color="$theme.primary"
        @input="updateList"
      ></v-text-field>
    </v-flex>
    <v-spacer></v-spacer>
  </div>
</template>
<script>
export default {
  props: ["filter"],
  methods: {
    updateList() {
      this.$forceUpdate();
    }
  }
};
</script>
